import {Box} from '@mui/material'
import React from 'react'
import {Route, useHistory} from 'react-router'

import {C60PartnerPage} from './layouts/C60.partner.page'
import {ROUTE as PartnersRoute, C60Route} from './PartnersRoute'
import Content from '../../Molecules/Content'

export const PartnersPage: React.FC = () => {
  const history = useHistory()
  const defaultPath = `${PartnersRoute}${C60Route}`
  React.useEffect(() => {
    history.push(defaultPath)
  }, [])
  return (
    <Content>
      <Box px={6.5} py={11}>
        <Route path={defaultPath} exact render={() => <C60PartnerPage />} />
      </Box>
    </Content>
  )
}
