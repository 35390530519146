import {TitleNav, Page, HCThemeType} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import {useFeaturesState} from '../../Organisms/Features'

import {BulkCementDeliverOrderForm} from '../../OrderIntake/BulkCement/Deliver/BulkCementDeliverOrderForm'
import {BulkCementDeliverOrderLegacyForm} from '../../OrderIntake/BulkCement/Deliver/BulkCementDeliverOrderLegacyForm'

import {makeStyles} from '@material-ui/core'
import Content from '../../Molecules/Content'
import SubHeader from '../../Molecules/SubHeader'

const useStyles = makeStyles((theme: HCThemeType) => {
  return {
    pageStyle: {
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(4)}px`
      },
      padding: 0
    }
  }
})

const BulkCementDeliveryOrderIntake: React.FC = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {getFeature} = useFeaturesState()
  const useLegacyDesign = getFeature('OrderIntakeUseLegacyDesign')

  const classes = useStyles()

  return (
    <Content>
      <SubHeader>
        <TitleNav title={t('overview.scheduleNewDelivery')} onClick={() => history.goBack()} />
      </SubHeader>
      <Page
        data-test-id="delivery-order-intake-page"
        className={!useLegacyDesign ? classes.pageStyle : ''}
      >
        {useLegacyDesign ? <BulkCementDeliverOrderLegacyForm /> : <BulkCementDeliverOrderForm />}
      </Page>
    </Content>
  )
}

export {BulkCementDeliveryOrderIntake}
