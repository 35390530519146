import {FeaturesCheck} from '@hconnect/common/components/FeaturesCheck'
import {ErrorText, Page, PageTitle, useBreakPoints} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core/styles'
import {Box, Grid, CircularProgress} from '@mui/material'
import {isEmpty} from 'lodash'
import find from 'lodash/find'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {api} from '../../App.global'
import {Widget} from '../../Molecules/Widget/Widget'
import {useIsFeatureEnabledForCurrentCustomer} from '../../OrderIntake/FeatureFlags'
import CashBalanceWidget from '../../Organisms/CashBalanceWidget/CashBalanceWidget'
import {selectCustomers} from '../../Organisms/Customers'
import {CustomerStateType} from '../../Organisms/Customers/Action.types'
import {Customer} from '../../Organisms/Customers/Customers.types'
import {CustomerSimpleLookup} from '../../Organisms/Customers/CustomerSimpleLookup'
import {selectErrorByKey} from '../../Organisms/Errors'
import {Error} from '../../Organisms/Errors/Errors.types'
import {Features, useFeaturesState} from '../../Organisms/Features'
import {GenericFilterBar} from '../../Organisms/Filters/GenericFilterBar'
import {useBulkCementOrderIntake} from '../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {fetchPayers, PayersStateType} from '../../Organisms/Payers'
import {selectPayers} from '../../Organisms/Payers/Payers.selectors'
import {PermissionTypes, usePermissions} from '../../Permissions'
import {AppState} from '../../Root.store'

import {InviteButton} from './Widgets/InviteButton'
import {InvoiceByDate} from './Widgets/InvoiceByDate'
import LastInvoices from './Widgets/LastInvoices'
import {OrderByStatus} from './Widgets/OrderByStatus'
import {OrderIntakeWidget} from './Widgets/OrderIntakeWidget'
import {OverviewTitle} from './Widgets/OverviewTitle'
import {RequestForQuote} from './Widgets/RequestForQuote'
import {PageNames} from '../../common/constants'
import Content from '../../Molecules/Content'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '3.625rem'
  },
  subtitle: {
    fontSize: 16,
    color: 'rgba(255, 255, 255, 0.96)'
  },
  fullWidth: {
    width: '100%'
  }
}))

// Constants
const DATE_RANGE = 29
const DISPLAYED_DATE_RANGE = DATE_RANGE + 1

// eslint-disable-next-line complexity
export const Overview = () => {
  const {fullWidth} = useStyles()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {grantedPermissionTypes, getPermissions} = usePermissions()
  const {getFeature} = useFeaturesState()
  const screenSize = useBreakPoints()
  const smallScreen = screenSize === 'xs' || screenSize === 'sm'

  // begin Filterbar setup
  const customersState = useSelector<AppState, CustomerStateType>(selectCustomers)
  const payersState = useSelector<AppState, PayersStateType>(selectPayers)

  const error: Error = useSelector<AppState, Error>((state: AppState) =>
    selectErrorByKey(state, 'customers')
  )
  const {customers, selectedCustomer, isFetching: isCustomersFetching} = customersState

  const {selectedPayer, isFetching: isPayersFetching, entities: payersEntities} = payersState
  const loading = isCustomersFetching || isPayersFetching

  const customer: Customer | undefined = selectedCustomer
    ? find(customers, {customerId: selectedCustomer.customerId})
    : // TODO: fix for regression
      undefined

  const isPermissionToOrderForSelectedCustomer = !isEmpty(
    getPermissions(PermissionTypes.CREATE_ORDERS).filter(
      (permission) => JSON.parse(permission.dataScope).customerId === selectedCustomer?.customerId
    )
  )
  const isOrderIntakeAvailableForCurrentCustomer = useIsFeatureEnabledForCurrentCustomer(
    selectedCustomer?.customerId,
    'OrderIntake'
  )
  const {displayCustomerSimpleLookup} = usePermissions()
  const {
    isCollect,
    isDelivery,
    isFetching: isFetchingOrderIntakeOptions,
    isNoAvailableContracts,
    isError: isOrderIntakeError
  } = useBulkCementOrderIntake()

  const showCashBalance =
    grantedPermissionTypes.has(PermissionTypes.VIEW_CREDIT) && getFeature('CashBalance')

  const showFinance =
    showCashBalance ||
    grantedPermissionTypes.has(PermissionTypes.VIEW_FINANCE) ||
    grantedPermissionTypes.has(PermissionTypes.VIEW_INVOICES) ||
    grantedPermissionTypes.has(PermissionTypes.VIEW_ALL_DATA)

  const showPayers = grantedPermissionTypes.has(PermissionTypes.VIEW_PAYERS)

  const customerSearchByNameEnabled = getFeature('CustomersByName')
  const showRequestForQuote = getFeature('OrderIntakeRequestForQuote')

  useEffect(() => {
    if (
      !isCustomersFetching &&
      selectedCustomer &&
      showPayers &&
      !payersEntities[selectedCustomer.customerId]
    ) {
      dispatch(fetchPayers(selectedCustomer.customerId))
    }
  }, [dispatch, isCustomersFetching, showPayers, selectedCustomer, payersEntities])

  if (error) {
    return (
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ErrorText label={t(`${error.translationKey}`)} />
      </Box>
    )
  }

  return (
    <Content>
      <Page
        data-test-id="overview-page"
        title={
          <Grid container>
            <Grid item xs={12} md={12}>
              {displayCustomerSimpleLookup
                ? t('overview.title')
                : !isCustomersFetching && (
                    <OverviewTitle
                      t={t}
                      customer={customer}
                      loading={loading}
                      showPayers={showPayers}
                      smallScreen={smallScreen}
                    />
                  )}
            </Grid>
          </Grid>
        }
      >
        {loading && !displayCustomerSimpleLookup && (
          <CircularProgress data-test-id="overview-loader" />
        )}
        <Grid
          container
          data-test-id="overview-container"
          rowSpacing={{xs: 2, md: 3}}
          columnSpacing={{md: 2}}
        >
          <Grid xs={12} md={12} item data-test-id="overview-title-widget" container>
            {/* show filterbar only if necessary */}
            {displayCustomerSimpleLookup ? (
              <Grid item xs={12} md={12} container>
                <GenericFilterBar
                  data-test-id="overview-filter-bar"
                  customerSearchByNameEnabled={customerSearchByNameEnabled}
                  showErrorAsInfo
                  toolbarProps={{disableGutters: true, className: fullWidth}}
                >
                  <Grid xs={12} md={4} item>
                    <Features name="CustomersByName" resolution="enabled">
                      <CustomerSimpleLookup
                        data-test-id="overview-filter-lookup"
                        customerSearchByName
                        dark
                        page={PageNames.OVERVIEW}
                      />
                    </Features>
                    <Features name="CustomersByName" resolution="disabled">
                      <CustomerSimpleLookup
                        data-test-id="overview-filter-lookup"
                        dark
                        page={PageNames.OVERVIEW}
                      />
                    </Features>
                  </Grid>
                  {!smallScreen ? <Grid item md={1} /> : null}
                  <Grid xs={12} md={6} item>
                    {!isCustomersFetching && (
                      <Box mb={1} mt={smallScreen ? 3 : 0}>
                        <OverviewTitle
                          t={t}
                          customer={customer}
                          loading={loading}
                          showPayers={showCashBalance}
                          salesAgent
                        />
                      </Box>
                    )}
                  </Grid>
                </GenericFilterBar>
              </Grid>
            ) : null}
          </Grid>
          {selectedCustomer ? (
            <Grid xs={12} md={12} item data-test-id="overview-invite-button">
              <FeaturesCheck api={api} name={['BusinessOwnerInviteUser', 'SalesAgentInviteUser']}>
                <InviteButton selectedCustomer={selectedCustomer} />
              </FeaturesCheck>
            </Grid>
          ) : null}

          <Features name="Finance">
            {showFinance ? (
              <Grid xs={12} md={12} item data-test-id="overview-finance-widget-title">
                <PageTitle data-test-id="section-title-invoices">{t('overview.finance')}</PageTitle>
              </Grid>
            ) : null}
            {showCashBalance && selectedPayer ? (
              <Features name="CashBalance">
                <Grid xs={12} md={12} item data-test-id="overview-finance-widget-cashbalance">
                  <CashBalanceWidget
                    isCustomersFetching={isCustomersFetching}
                    customer={selectedCustomer}
                    onRetry={() => {}}
                    smallScreen={smallScreen}
                    onDark
                  />
                </Grid>
              </Features>
            ) : null}
            {grantedPermissionTypes.has(PermissionTypes.VIEW_INVOICES) ? (
              <Grid xs={12} md={6} item data-test-id="overview-finance-widget-invoicesByDate">
                <Widget title={t('overview.invoiceByDate')} data-test-id="widget-invoice-by-date">
                  <InvoiceByDate />
                </Widget>
              </Grid>
            ) : null}
            {grantedPermissionTypes.has(PermissionTypes.VIEW_INVOICES) ? (
              <Grid item xs={12} md={6} data-test-id="overview-finance-widget-lastInvoices">
                <Widget
                  title={t('overview.latestInvoices', {days: DISPLAYED_DATE_RANGE})}
                  data-test-id="widget-title-latest-invoices"
                >
                  <LastInvoices dateRange={DATE_RANGE} displayDateRange={DISPLAYED_DATE_RANGE} />
                </Widget>
              </Grid>
            ) : null}
          </Features>
          {grantedPermissionTypes.has(PermissionTypes.VIEW_ORDERS_DELIVERIES) ? (
            <Grid item xs={12} md={12} data-test-id="overview-orders-widget-title">
              <PageTitle data-test-id="section-title-orders">{t('overview.orders')}</PageTitle>
            </Grid>
          ) : null}
          {grantedPermissionTypes.has(PermissionTypes.CREATE_ORDERS) &&
          isOrderIntakeAvailableForCurrentCustomer ? (
            <Grid xs={12} md={6} item data-test-id="overview-orders-widget-intake" container>
              <OrderIntakeWidget
                isOrderIntakeError={isOrderIntakeError}
                isCollect={isCollect}
                isDelivery={isDelivery}
                isFetchingOrderIntakeOptions={isFetchingOrderIntakeOptions}
                isNoAvailableContracts={isNoAvailableContracts}
                isPermissionToOrderForSelectedCustomer={isPermissionToOrderForSelectedCustomer}
              />
            </Grid>
          ) : null}
          {grantedPermissionTypes.has(PermissionTypes.VIEW_ORDERS_DELIVERIES) ? (
            <Grid xs={12} md={6} item data-test-id="overview-orders-widget-ordersByStatus">
              <Widget title={t('overview.ordersByStatus')} data-test-id="widget-order-by-status">
                <OrderByStatus />
              </Widget>
            </Grid>
          ) : null}
          {showRequestForQuote && !isFetchingOrderIntakeOptions ? (
            <Grid xs={12} md={12} item data-test-id="overview-quote-widget-title">
              <PageTitle data-test-id="section-title-orders">
                {t('overview.requestForQuote')}
              </PageTitle>
            </Grid>
          ) : null}
          {showRequestForQuote && !isFetchingOrderIntakeOptions ? (
            <Grid item data-test-id="overview-quote-widget-getQuote">
              <Widget
                title={t('overview.requestForQuoteWidgetTitle')}
                subTitle={t('orderIntake.requestForQuote.requestForQuoteCaption')}
                data-test-id="widget-schedule-new-delivery"
              >
                <RequestForQuote />
              </Widget>
            </Grid>
          ) : null}
        </Grid>
      </Page>
    </Content>
  )
}
