import {Theme, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {TFunction} from 'i18next'
import {useState} from 'react'
import {makeStyles} from 'tss-react/mui'

interface DeliveriesServicesSwitchProps {
  t: TFunction
  disableServicesSwitch?: boolean
  disableDeliveriesSwitch?: boolean
  setSelectedSwitch: (selectedSwitch: TableButtonSwitch) => void
}
export const useStyles = makeStyles()((theme: Theme) => ({
  switchGroup: {
    margin: `${theme.spacing(3)} 0`
  },
  tableSwitchButtons: {
    padding: '12px 56px',
    fontWeight: '500',
    borderRadius: '7px',
    border: 'none !important',

    '&&.Mui-selected': {
      backgroundColor: '#016AD4',
      border: '1px solid #016AD4'
    }
  }
}))

export enum TableButtonSwitch {
  deliveries = 'deliveries',
  services = 'services'
}

export const DeliveriesServicesSwitch: React.FC<DeliveriesServicesSwitchProps> = ({
  t,
  disableServicesSwitch,
  disableDeliveriesSwitch,
  setSelectedSwitch
}) => {
  const [selectedTab, setSelectedTab] = useState(TableButtonSwitch.deliveries)
  const {
    classes: {tableSwitchButtons, switchGroup}
  } = useStyles()

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedTab: TableButtonSwitch
  ) => {
    if (newSelectedTab !== null) {
      setSelectedTab(newSelectedTab)
      setSelectedSwitch(newSelectedTab)
    }
  }
  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedTab}
      exclusive
      onChange={handleChange}
      className={switchGroup}
    >
      <ToggleButton
        value={TableButtonSwitch.deliveries}
        className={tableSwitchButtons}
        disabled={disableDeliveriesSwitch}
        data-test-id="deliveries-switch"
      >
        {t(`order.${TableButtonSwitch.deliveries}`)}
      </ToggleButton>
      <ToggleButton
        value={TableButtonSwitch.services}
        className={tableSwitchButtons}
        disabled={disableServicesSwitch}
        data-test-id="services-switch"
      >
        {t(`order.${TableButtonSwitch.services}`)}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
