import {User} from '@hconnect/apiclient/src'
import DownloadIcon from '@mui/icons-material/Download'
import {Button, Menu, MenuItem, Theme} from '@mui/material'
import {useCallback, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles} from 'tss-react/mui'
import {v4 as uuidV4} from 'uuid'

import {sheetRequest} from '../../../../AsyncJobQueue/AsyncJobQueue.action'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes
} from '../../../../AsyncJobQueue/AsyncJobQueue.types'
import {trackEvent} from '../../../../common/analytics'
import {selectSelectedCustomer} from '../../../../Organisms/Customers'
import {Customer} from '../../../../Organisms/Customers/Customers.types'
import {useFeaturesState} from '../../../../Organisms/Features'
import {AppState} from '../../../../Root.store'
import {Order} from '../../Order.types'

interface ExportDeliveriesButtonProps {
  order?: Order
  customerId: string
}

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    width: '100%',
    color: '#00274D',
    height: '48px',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#f7fcff'
    },
    boxShadow: '0px 2px 2px 0px #00000014',
    border: '1px solid #00274D1A',
    marginBottom: theme.spacing(2)
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: 700,
    height: '48px'
  }
}))

export const ExportDeliveriesButton: React.FC<ExportDeliveriesButtonProps> = ({
  order,
  customerId
}) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {getFeature} = useFeaturesState()

  const isPumpServiceIncluded = getFeature('IncludePumpService')

  const selectedCustomer = useSelector<AppState, Customer | undefined>((state: AppState) =>
    selectSelectedCustomer(state, customerId)
  )
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  const [jobId, setJobId] = useState<string | undefined>(undefined)
  const [isOpen, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const {host} = (window as Window).location
  const url = `${RegExp(/localhost/).exec(host) ? 'http' : 'https'}://${host}/downloadRequests`

  const close = () => {
    setOpen(false)
    setJobId(undefined)
  }
  const handleToggleOpen = useCallback(() => {
    setOpen(!isOpen)
    handleExportClick()
  }, [setOpen, isOpen])

  const handleExportClick = () => {
    const jobIdTemp = uuidV4()
    setJobId(jobIdTemp)
    trackEvent('hubExportClick', {
      product: 'hub',
      jobId: jobIdTemp,
      entryPoint: 'order',
      userId: user?.user_id || '',
      userCountry: user?.country,
      customerId,
      customerName: selectedCustomer?.customerName,
      lookUpQuery: order?.orderNumber,
      lookUpCategory: 'orderNumber'
    })
  }

  const exportOrder = (format: ApiDownloadRequestFormats) => {
    if (!order) return
    dispatch(
      sheetRequest({
        jobId,
        criteria: {
          customerId,
          orderNumber: order.orderNumber,
          ...(isPumpServiceIncluded ? {includePumpService: true} : {})
        },
        type: ApiDownloadRequestTypes.orderDeliveryExport,
        format: format,
        name: `order-deliveries-${order.orderNumber}.${format}`,
        shortName: `order-${order.orderNumber}`,
        fileType: format === ApiDownloadRequestFormats.xlsx ? 'Excel' : 'CSV',
        dateRangeInDays: '1',
        email: user?.eMail || '',
        sendEmail: true,
        fullName: user?.name || '',
        url,
        country: user?.country || '',
        clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        analytics: {
          jobId,
          numberOfDaysSelected: '1',
          initiationSource: 'hub'
        }
      })
    )
    trackEvent('hubExportStart', {
      product: 'hub',
      jobId,
      fileFormat: format === ApiDownloadRequestFormats.xlsx ? 'excel' : 'csv',
      entryPoint: 'order',
      userId: user?.user_id || '',
      userCountry: user?.country,
      customerId,
      customerName: selectedCustomer?.customerName
    })
    close()
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<DownloadIcon />}
        ref={buttonRef}
        onClick={handleToggleOpen}
        data-test-id="orders-export-dropdown"
      >
        {t('order.export.buttontitle')}
      </Button>
      <Menu
        open={isOpen}
        onClose={close}
        anchorEl={buttonRef.current}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          data-test-id="orders-excel-export"
          key={t('order.export.ordersToExcel')}
          onClick={() => exportOrder(ApiDownloadRequestFormats.xlsx)}
          className={classes.menuItem}
        >
          {t('order.export.ordersToExcel')}
        </MenuItem>
        <MenuItem
          data-test-id="orders-csv-export"
          key={t('order.export.ordersToCsv')}
          onClick={() => exportOrder(ApiDownloadRequestFormats.csv)}
          className={classes.menuItem}
        >
          {t('order.export.ordersToCsv')}
        </MenuItem>
      </Menu>
    </>
  )
}
