import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Typography} from '@hconnect/uikit'
import {Box, LinearProgress, Button, IconButton, Menu, MenuItem} from '@material-ui/core'
import PlusIcon from '@material-ui/icons/Add'
import {isEmpty} from 'lodash'
import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {InfoTextWithIcon} from '../../../Molecules/InfoTextWithIcon'
import {ShippingType} from '../../../OrderIntake/declarations/OrderIntake.enums'
import {
  useIsFeatureEnabledForCurrentCustomer,
  useIsOrderIntakeAvailableInCurrentCountry
} from '../../../OrderIntake/FeatureFlags'
import {CustomerStateType, selectCustomers} from '../../../Organisms/Customers'
import {useBulkCementOrderIntake} from '../../../Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {usePermissions, PermissionTypes} from '../../../Permissions'
import {useRolesByCustomerId} from '../../../Roles'
import {AppState} from '../../../Root.store'
import {getUserProfile} from '../../../UserProfile/UserProfile.selectors'

const OrderActionButton: React.FC<{
  smallScreen: boolean
  onClick: () => void
  testId: string
  text: string
  caption?: string
}> = ({smallScreen, onClick, testId, text, caption}) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="flex-end"
    marginBottom={smallScreen ? 0 : 1}
  >
    <Button
      onClick={onClick}
      color={smallScreen ? 'secondary' : 'inherit'}
      variant={smallScreen ? 'contained' : 'outlined'}
      style={{width: smallScreen ? '300px' : 'max-content'}}
      data-test-id={testId}
    >
      {text}
    </Button>
    {caption && (
      <Typography
        variant="caption"
        customColor="textSecondarySoft"
        style={{width: smallScreen ? '300px' : 'max-content', whiteSpace: 'normal', marginTop: 4}}
      >
        {caption}
      </Typography>
    )}
  </Box>
)

const SmallScreenButtons: React.FC<{buttons: (JSX.Element | undefined)[]}> = ({buttons}) => {
  const anchorEl = useRef()
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  buttons = buttons.filter((x) => x !== undefined)
  if (buttons.length === 0) return null

  return (
    <>
      <IconButton
        data-test-id="order-intake-buttons-plus"
        color="primary"
        style={{background: '#FFFFFF', margin: 6, border: 0}}
        onClick={() => setShowDropdown(!showDropdown)}
        buttonRef={anchorEl}
      >
        <PlusIcon />
      </IconButton>
      <Menu
        open={showDropdown}
        onClose={() => setShowDropdown(false)}
        anchorEl={anchorEl.current}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {buttons.map((b, i) => (
          <MenuItem key={i}>{b}</MenuItem>
        ))}
      </Menu>
    </>
  )
}
// eslint-disable-next-line complexity
const OrderIntakeButtons: React.FC<{smallScreen?: boolean}> = ({smallScreen = false}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {
    isCollect,
    isDelivery,
    isFetching: isFetchingOrderIntakeOptions,
    isNoAvailableContracts,
    isError: isOrderIntakeError
  } = useBulkCementOrderIntake()
  const {grantedPermissionTypes, getPermissions} = usePermissions()
  const customersState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const {selectedCustomer} = customersState
  const userProfile = useSelector<AppState, User | null>(
    (state) => getUserProfile(state).userProfile
  )

  const {data: roles} = useRolesByCustomerId(selectedCustomer?.customerId)

  const isOrderIntakeAvailableForCurrentCustomer = useIsFeatureEnabledForCurrentCustomer(
    selectedCustomer?.customerId,
    'OrderIntake'
  )

  const isOrderIntakeAvailableForCurrentCountry = useIsOrderIntakeAvailableInCurrentCountry(
    userProfile?.country,
    'OrderIntake'
  )

  const isPermissionToOrderForSelectedCustomer = !isEmpty(
    getPermissions(PermissionTypes.CREATE_ORDERS).filter(
      (permission) => JSON.parse(permission.dataScope).customerId === selectedCustomer?.customerId
    )
  )

  const showOrderIntake = grantedPermissionTypes.has(PermissionTypes.CREATE_ORDERS)

  const isDeliverDisabled = useIsFeatureEnabledForCurrentCustomer(
    selectedCustomer?.customerId,
    'OrderIntakeDisableDelivered'
  )

  const handleDeliverOrderIntakeOpen = () => {
    history.push('/bulkCementDelivery')
    trackEvent('hubOrderIntakeStart', {
      product: 'hub',
      customerId: selectedCustomer?.customerId,
      customerName: selectedCustomer?.customerName,
      userCountry: userProfile?.country,
      userId: userProfile?.user_id,
      role: roles.map((role) => role.roleType).join(','),
      shippingType: ShippingType.DELIVER,
      entryPoint: 'Orders & Deliveries'
    })
  }

  const handleCollectOrderIntakeOpen = () => {
    history.push('/bulkCementCollection')
    trackEvent('hubOrderIntakeStart', {
      product: 'hub',
      customerId: selectedCustomer?.customerId,
      customerName: selectedCustomer?.customerName,
      userCountry: userProfile?.country,
      userId: userProfile?.user_id,
      role: roles.map((role) => role.roleType).join(','),
      shippingType: ShippingType.COLLECT,
      entryPoint: 'Orders & Deliveries'
    })
  }

  const scheduleNewDeliveryButton =
    (isDelivery &&
      !isDeliverDisabled &&
      isPermissionToOrderForSelectedCustomer &&
      !isFetchingOrderIntakeOptions && (
        <OrderActionButton
          smallScreen={smallScreen}
          onClick={handleDeliverOrderIntakeOpen}
          text={t('overview.scheduleNewDelivery')}
          testId="orders-deliveries-order-intake-deliver-button"
        />
      )) ||
    undefined
  const orderIntakeCollectButton =
    (isCollect && isPermissionToOrderForSelectedCustomer && !isFetchingOrderIntakeOptions && (
      <OrderActionButton
        smallScreen={smallScreen}
        onClick={handleCollectOrderIntakeOpen}
        text={t('overview.orderIntakeCollectWidgetTitle')}
        caption={t('orderIntake.collect.generateQrCodeCaption')}
        testId="orders-deliveries-order-intake-collect-button"
      />
    )) ||
    undefined

  if (!isOrderIntakeAvailableForCurrentCountry) return null

  if (showOrderIntake && !isOrderIntakeAvailableForCurrentCustomer)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        data-test-id="orders-deliveries-order-intake-no-allowed-to-place-order"
      >
        <InfoTextWithIcon text={t('orderIntake.notAllowedToPlaceOrderForThisCustomer')} />
      </Box>
    )

  if (isFetchingOrderIntakeOptions) {
    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          data-test-id="orders-deliveries-order-intake-loading"
        >
          <LinearProgress data-test-id="orders-deliveries-order-intake-loader" />
          <Box p={0.25} />
          <Typography variant="caption">{t('overview.loadingContracts')}</Typography>
        </Box>
      </>
    )
  }
  return (
    <>
      {showOrderIntake && isOrderIntakeError && (
        <Box
          display="flex"
          flexDirection="column"
          data-test-id="orders-deliveries-order-intake-error"
        >
          <Typography customVariant="captionBold">
            {t('overview.placeNewOrder').toUpperCase()}
          </Typography>
          <Box p={0.25} />
          <Typography variant="caption" color="error">
            {t('orderIntake.errorMessage')}
          </Typography>
        </Box>
      )}

      {showOrderIntake && isNoAvailableContracts && (
        <Box
          display="flex"
          flexDirection="column"
          data-test-id="orders-deliveries-order-intake-no-contracts"
        >
          <Typography customVariant="captionBold">
            {t('overview.placeNewOrder').toUpperCase()}
          </Typography>
          <Box p={0.5} />
          <InfoTextWithIcon text={t('orderIntake.noAvailableContracts')} />
          <Box p={0.5} />
        </Box>
      )}

      {smallScreen ? (
        <SmallScreenButtons buttons={[scheduleNewDeliveryButton, orderIntakeCollectButton]} />
      ) : (
        <>
          {scheduleNewDeliveryButton}
          {orderIntakeCollectButton}
        </>
      )}
    </>
  )
}

export {OrderIntakeButtons}
