import {User} from '@hconnect/apiclient'
import {TFunction} from 'i18next'
import find from 'lodash/find'
import {Dispatch} from 'redux'
import {v4 as uuidV4} from 'uuid'

import {trackEvent} from '../../common/analytics'
import {BusinessLineType, BusinessLineTypes} from '../../common/types'
// eslint-disable-next-line import/no-cycle
import {Entity, EntityPage, downloadDocument, getDocumentFileName} from '../../Organisms/Documents'
import {useFeaturesState} from '../../Organisms/Features'
import {
  Delivery,
  DeliveryEventTimeStampTypes,
  Link as DeliveryLink,
  LinkType,
  DeliveryStatusType,
  DeliveryStatusTypes,
  RelatedEntity,
  LinkMedia
} from '../Finance/Invoices/Delivery.types'

import {Order, OrderStatus, ShippingType} from './Order.types'

export const getIsCEM = (item: Order) =>
  item.businessLine && item.businessLine === BusinessLineType.CEM
export const getIsRMC = (item: Order) =>
  item.businessLine && item.businessLine === BusinessLineType.RMC
export const getIsAGG = (item: Order) =>
  item.businessLine && item.businessLine === BusinessLineType.AGG

// If the feature toggle "DeliveryLoadFinished" is set true, use loadFinished delivery event.
// if unloadingFinished delivery event is available use the date for COMPLETED or Invoiced in expanded delivery list view
// loadFinished delivery event when the shippingType is COLLECT for COMPLETED
// when shippingType is DELIVER and we only have loadFinished, then leave COMPLETED blank.
// Returns an ISO date string as delivered by the backend or `null`.
export const getFinishedDate = (
  delivery: Delivery,
  isDeliveryLoadFinished: boolean,
  businessLine?: BusinessLineTypes
): {date?: string; accuracy?: DeliveryEventTimeStampTypes} => {
  const {deliveryEvents, shippingType, deliveryEventsAccuracy: accuracy} = delivery
  const {loadFinished, unloadFinished} = deliveryEvents

  if (
    isDeliveryLoadFinished ||
    shippingType === ShippingType.Collect ||
    businessLine === BusinessLineType.AGG
  ) {
    return loadFinished ? {date: loadFinished, accuracy: accuracy?.loadFinished} : {}
  }
  if (unloadFinished) {
    return {date: unloadFinished, accuracy: accuracy?.unloadFinished}
  }
  return {}
}

export const hasOrderDeliveries = (order: Order): boolean => {
  const noDeliveriesAvailableOrderStatus = [
    OrderStatus.Pending,
    OrderStatus.Reserved,
    OrderStatus.Confirmed
  ]
  return (
    !noDeliveriesAvailableOrderStatus.includes(order.orderStatus) ||
    !!(order.deliveries && order.deliveries.length > 0)
  )
}

export const isOrderExpandable = (order: Order): boolean =>
  order.lineItems.length > 0 && hasOrderDeliveries(order)

// Converts NWG UTC offset format to offset in minutes
// Examples
// offsetStringToOffset('P0200'): 120
// offsetStringToOffset('M0130'): -90
const offsetStringToOffset = (offsetString?: string) => {
  if (!offsetString || offsetString.length !== 5) return undefined
  let offset = parseInt(offsetString.substring(1, 3)) * 60 + parseInt(offsetString.substring(3))
  if (offsetString[0] === 'M') offset = offset * -1

  return offset
}

export const getEventLocationOffset = (item: Order) => {
  return item.shippingType === ShippingType.Collect
    ? offsetStringToOffset(item.shipFromUtcOffset)
    : offsetStringToOffset(item.shipToUtcOffset)
}

export interface FindDocuments {
  proofOfDelivery?: DeliveryLink
  deliveryNote?: DeliveryLink
  imageProof?: DeliveryLink
}
export const findDocuments = (delivery: Delivery): FindDocuments => {
  const response: FindDocuments = {}
  if ('links' in delivery && Array.isArray(delivery.links)) {
    const proofOfDelivery = find(
      delivery.links,
      (link) => link.rel === 'documents' && link.type === LinkType.ProofOfDelivery
    )
    const deliveryNote = find(
      delivery.links,
      (link) => link.rel === 'documents' && link.type === LinkType.DeliveryNote
    )
    const imageProof = find(
      delivery.links,
      (link) => link.rel === 'documents' && link.type === LinkType.ImageProof
    )
    response.proofOfDelivery = proofOfDelivery
    response.deliveryNote = deliveryNote
    response.imageProof = imageProof
  }
  return response
}

export const extractOrderNumber = (str: string): string => {
  const regex = /\.(.*?)\./
  const match = regex.exec(str)
  if (match !== null && match.length >= 2) {
    return match[1]
  }
  return ''
}

export const extractMultipleOrderNumbers = (ordersNumbersArr: string[]): string[] => {
  return ordersNumbersArr.map((order: string) => extractOrderNumber(order))
}

// eslint-disable-next-line complexity
export const getUnloadingMethod = (t: TFunction, unloadingMethodCode?: string): string | null => {
  if (!unloadingMethodCode) {
    return null
  }

  // if it's not a single character code return raw value
  if (unloadingMethodCode.length > 1) {
    return unloadingMethodCode
  }

  switch (unloadingMethodCode) {
    case '1':
      return t('order.orderDetails.unloadingMethod.stationaryCustomer')
    case '2':
      return t('order.orderDetails.unloadingMethod.siteSilo')
    case '3':
      return t('order.orderDetails.unloadingMethod.siteSpreader')
    case '4':
      return t('order.orderDetails.unloadingMethod.foilTube')
    case '5':
      return t('order.orderDetails.unloadingMethod.canonDischarge')
    case '6':
      return t('order.orderDetails.unloadingMethod.opencastMining')
    case '7':
      return t('order.orderDetails.unloadingMethod.individualValue')
    case '8':
      return t('order.orderDetails.unloadingMethod.riser')
    case '9':
      return t('order.orderDetails.unloadingMethod.buildingChemestry')
    case 'A':
      return t('order.orderDetails.unloadingMethod.tipping')
    case 'B':
      return t('order.orderDetails.unloadingMethod.bucket')
    case 'C':
      return t('order.orderDetails.unloadingMethod.continuousDischarge')
    case 'D':
      return t('order.orderDetails.unloadingMethod.craneSmallBucket')
    case 'E':
      return t('order.orderDetails.unloadingMethod.thirdPartyPump')
    case 'F':
      return t('order.orderDetails.unloadingMethod.conveyorBelt')
    case 'G':
      return t('order.orderDetails.unloadingMethod.wheelLoader')
    case 'H':
      return t('order.orderDetails.unloadingMethod.fundationPile')
    case 'I':
      return t('order.orderDetails.unloadingMethod.screedPump')
    case 'J':
      return t('order.orderDetails.unloadingMethod.curbstone')
    case 'K':
      return t('order.orderDetails.unloadingMethod.crane')
    case 'L':
      return t('order.orderDetails.unloadingMethod.hydraulicChute')
    case 'M':
      return t('order.orderDetails.unloadingMethod.pumi')
    case 'N':
      return t('order.orderDetails.unloadingMethod.helicopterDelivery')
    case 'O':
      return t('order.orderDetails.unloadingMethod.ownContractedPump')
    case 'P':
      return t('order.orderDetails.unloadingMethod.customerPump')
    case 'Q':
      return t('order.orderDetails.unloadingMethod.pumpOnly')
    case 'R':
      return t('order.orderDetails.unloadingMethod.pipe')
    case 'S':
      return t('order.orderDetails.unloadingMethod.stamix')
    case 'T':
      return t('order.orderDetails.unloadingMethod.pipePump')
    case 'U':
      return t('order.orderDetails.unloadingMethod.unknown')
    case 'V':
      return t('order.orderDetails.unloadingMethod.paver')
    case 'W':
      return t('order.orderDetails.unloadingMethod.wheelbarrow')
    case 'X':
      return t('order.orderDetails.unloadingMethod.conveyorBelt')
    default:
      return t('order.orderDetails.unloadingMethod.unknown')
  }
}

// eslint-disable-next-line complexity
export const getOrderReason = (t: TFunction, orderReasonCode?: string): string | null => {
  if (!orderReasonCode) {
    return null
  }
  switch (orderReasonCode) {
    case '01':
      return t('order.orderDetails.orderReason.confirmed')
    case '02':
      return t('order.orderDetails.orderReason.tbcByCustomer')
    case '03':
      return t('order.orderDetails.orderReason.reserved')
    case '04':
      return t('order.orderDetails.orderReason.confirmedInternalValidation')
    case '05':
      return t('order.orderDetails.orderReason.confirmedCriticalRawMaterial')
    case '06':
      return t('order.orderDetails.orderReason.confirmedMissingPump')
    case '07':
      return t('order.orderDetails.orderReason.noConfirmedPayment')
    case '08':
      return t('order.orderDetails.orderReason.tbcByHm')
    case '11':
      return t('order.orderDetails.orderReason.lpConfirmedByCustomerTbrByHm')
    case '12':
      return t('order.orderDetails.orderReason.lpReservedOrPendingByCustomerTbrByHm')
    case '13':
      return t('order.orderDetails.orderReason.fpConfirmedByHm')
    case '14':
      return t('order.orderDetails.orderReason.fpUnconfirmedByHm')
    default:
      return t('order.orderDetails.orderReason.unknown')
  }
}

export const isDeliveryNoteOrProofOfDelivery = (link: DeliveryLink) =>
  link.rel === 'documents' &&
  (link.type === LinkType.DeliveryNote || link.type === LinkType.ProofOfDelivery)

export const useAllFeaturesOrderItems = () => {
  const {getFeature} = useFeaturesState()
  const isGroupedDeliveries = getFeature('OrderIntakeGroupedDeliveries')
  const isPumpServiceIncluded = getFeature('IncludePumpService')
  const isServiceOrderIncluded = getFeature('IncludeServiceOrder')
  const isFilteringOutCancelledDeliveries =
    getFeature('ExcludeAllCancelledDeliveries') || getFeature('ExcludeNotLoadedCancelledDeliveries')
  const isFilteringCancelledOrders = getFeature('OrderIntakeDisplayCancelledOrders')
  const includeDeliveryInstructions = getFeature('IncludeDeliveryInstructions')
  const expandToOrderDocuments = getFeature('OrderDocuments')
  const useHaulierView = getFeature('UseHaulierView')

  return {
    isGroupedDeliveries,
    isPumpServiceIncluded,
    isServiceOrderIncluded,
    isFilteringOutCancelledDeliveries,
    isFilteringCancelledOrders,
    includeDeliveryInstructions,
    expandToOrderDocuments,
    useHaulierView
  }
}

export function showWarning(
  item: Order,
  t: TFunction,
  isDeliveryNotesDisabled: boolean
): {showWarningIcon: boolean; msg: string} {
  let msg = ''
  let showWarningIcon = false

  if (isDeliveryNotesDisabled || !item.deliveries?.length) {
    return {showWarningIcon, msg}
  }
  // Look for list of enabled delivery statuses https://jira.heidelbergcement.com/browse/HCP-55032
  const deliveryStatus = item.deliveries.some((delivery: Delivery) =>
    (
      [
        DeliveryStatusType.inTransit,
        DeliveryStatusType.waitingForUnload,
        DeliveryStatusType.unloading,
        DeliveryStatusType.unloaded,
        DeliveryStatusType.returned,
        DeliveryStatusType.completed,
        DeliveryStatusType.invoiced
      ] as DeliveryStatusTypes[]
    ).includes(delivery.deliveryStatus)
  )

  if (!deliveryStatus) {
    return {showWarningIcon, msg}
  }

  const numberOfDeliveries = item.deliveries.length
  const numPDFs =
    item.deliveries
      .map((x) => x.links || [])
      .flat()
      .filter((link) => isDeliveryNoteOrProofOfDelivery(link)).length || 0

  if (numPDFs === 0) {
    msg = t('order.multiple.ticketNoPDFAvailable')
    showWarningIcon = true
  } else if (numberOfDeliveries > numPDFs) {
    msg = t('order.multiple.ticketNotAllPDFsAvailable')
    showWarningIcon = true
  }

  return {showWarningIcon, msg}
}

export const getExtras = (item?: Order) => {
  const extras = item?.supplementItems || []
  return extras.map((extra) => extra.itemName).join(', ')
}

export const downloadOrderConfirmation = (
  order: Order,
  user: User | null,
  entryPoint: string,
  t: TFunction,
  dispatch: Dispatch<any>
) => {
  const {orderNumber, customer, placedDate, businessLine} = order
  const orderConfirmationPdf = getOrderConfirmationLink(order)
  if (!orderConfirmationPdf) return

  const documentId = orderConfirmationPdf.href ? orderConfirmationPdf.href.split('/')[1] : ''
  const fileName = getDocumentFileName(
    EntityPage.orders,
    Entity.orderConfirmation,
    LinkMedia.Pdf,
    t,
    {
      entityNumber: orderNumber
    }
  )

  const jobId: string = uuidV4()
  trackEvent('hubDownloadSingle', {
    product: 'hub',
    jobId: jobId,
    downloadedItem: Entity.orderConfirmation,
    linkToDownloadedItem: orderConfirmationPdf.href,
    customerId: customer?.customerId,
    entryPoint: entryPoint,
    issuedDate: placedDate,
    userId: user?.user_id,
    userMail: user?.eMail,
    userCountry: user?.country,
    userRoles: user?.hasRoles,
    userIsInternal: user?.isInternal,
    downloadedItemBusinessLine: businessLine
  })
  dispatch(downloadDocument(jobId, documentId, fileName))
}

export const getOrderConfirmationLink = (order: Order) => {
  if (!order?.links || !Array.isArray(order.links) || order.links.length < 1) return
  return order.links.find(
    (link) => link.rel === RelatedEntity.documents && link.type === LinkType.OrderConfirmation
  )
}
