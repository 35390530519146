import {Page, timeFormatter} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core/styles'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import MaintenanceScreenImage from './MaintenanceScreenImage.png'
import Content from '../../Molecules/Content'

const useStyles = makeStyles({
  maintenanceMessage: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  image: {
    margin: 16,
    maxWidth: '100%',
    width: 350
  },
  info: {
    maxWidth: 600
  }
})

type MaintenanceScreenType = {
  startDate?: Date
  durationInHours?: number
}

export const MaintenanceScreen: React.FC<MaintenanceScreenType> = ({
  startDate,
  durationInHours
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const infoText = startDate
    ? durationInHours
      ? t('maintenanceScreen.info', {
          startDate: timeFormatter(startDate),
          durationHours: durationInHours
        })
      : t('maintenanceScreen.infoNoDuration', {startDate: timeFormatter(startDate)})
    : t('maintenanceScreen.infoNoStartDate')

  return (
    <Content>
      <Page px={0} py={0}>
        <Box data-test-id="maintenance-screen" className={classes.maintenanceMessage}>
          <img className={classes.image} src={MaintenanceScreenImage} alt="" />
          <Typography m={1} variant="h2">
            {t('maintenanceScreen.headline')}
          </Typography>
          <Typography data-test-id="maintenance-screen-info" mx={1} className={classes.info}>
            {infoText}
          </Typography>
        </Box>
      </Page>
    </Content>
  )
}
