import {PrimaryCell, ResponsiveTable, TableColumnType} from '@hconnect/uikit'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useResponsiveBreakpoints} from '../../../../Molecules/Responsive.utils'
import {useFeaturesState} from '../../../../Organisms/Features'
import {usePermissions} from '../../../../Permissions'
import {Delivery} from '../../../Finance/Invoices/Delivery.types'
import {DeliveryCardList} from '../../components'
import {
  DeliveryArrivalTime,
  DeliveryLeftAt,
  DeliveryStatus,
  DeliveryTicketNumber,
  DeliveryVehicle
} from '../../components/DeliveryFields'
import {QuantityUomToSymbolComponent} from '../../Order.components'
import {Order} from '../../Order.types'

import {DeliveriesServicesSwitch, TableButtonSwitch} from './DeliveriesServicesSwitch'
import {filterDeliveriesIfService, getDeliveryToggles} from './utils'

interface DeliveriesTableProps {
  order?: Order
  deliveries?: Delivery[]
  customerId: string
  analytics?: any
  setSelectedDelivery: (delivery: Delivery) => void
  isFetching: boolean
}

export const DeliveriesTable: React.FC<DeliveriesTableProps> = ({
  order,
  deliveries,
  customerId,
  analytics,
  setSelectedDelivery,
  isFetching
}) => {
  const {t} = useTranslation()
  const features = useFeaturesState()
  const permissions = usePermissions()
  const {smallScreen} = useResponsiveBreakpoints()
  const [selectedSwitch, setSelectedSwitch] = useState(TableButtonSwitch.deliveries)
  const [{serviceDeliveries, materialDeliveries}, setDeliveries] = useState(() =>
    filterDeliveriesIfService(deliveries)
  )
  useEffect(() => {
    setDeliveries(filterDeliveriesIfService(deliveries))
  }, [deliveries])

  if (!order) return null

  const toggles = getDeliveryToggles(features, permissions, order)
  const rowsPool: {[key: string]: TableColumnType<Delivery>} = {
    ticketNumber: {
      field: 'ticket-number',
      headerName: t('order.expand.deliveryNumber'),
      renderCell: (delivery: Delivery) => (
        <DeliveryTicketNumber
          delivery={delivery}
          toggles={toggles}
          customerId={customerId}
          analytics={analytics}
        />
      )
    },
    leftPlantAt: {
      field: 'left-plant-at',
      headerName: toggles.isDeliveryLoadFinished
        ? t('order.expand.leftAt')
        : t('order.expand.shippingDate'),

      renderCell: (delivery: Delivery) => (
        <DeliveryLeftAt
          delivery={delivery}
          isDeliveryLoadFinished={toggles.isDeliveryLoadFinished}
          isSloCountry={toggles.isSloCountry}
          businessLine={order.businessLine}
        />
      )
    },
    arrival: {
      field: 'arrival',
      headerName: t('order.expand.arrival'),
      renderCell: (delivery: Delivery) => <DeliveryArrivalTime delivery={delivery} />
    },
    quantity: {
      field: 'quantity',
      headerName: toggles.isRMC ? t('order.expand.quantity') : t('order.expand.qtyLoaded'),
      renderCell: (delivery: Delivery) => (
        <>
          {delivery.loadQuantity}{' '}
          <QuantityUomToSymbolComponent uom={delivery.loadQuantityUom || ''} />
        </>
      )
    },
    plantName: {
      field: 'plant-name',
      headerName: t('order.orderDetails.plantName'),
      renderCell: (delivery: Delivery) => delivery.plantName || '-'
    },
    vehicle: {
      field: 'vehicle',
      headerName: t(`order.expand.modeoftransport.${order.modeOfTransport || 'others'}`),
      renderCell: (delivery: Delivery) => (
        <DeliveryVehicle
          delivery={delivery}
          customerId={customerId}
          toggles={toggles}
          analytics={analytics}
        />
      )
    },
    status: {
      field: 'status',
      headerName: t('order.orderDetails.statusLabel'),
      renderCell: (delivery: Delivery) => (
        <DeliveryStatus delivery={delivery} toggles={toggles} customerId={customerId} />
      )
    },
    relatedDelivery: {
      field: 'related-delivery',
      noColumnSelection: true,
      headerName: t('order.expand.relatedDelivery'),
      renderCell: (delivery: Delivery) =>
        delivery.relatedDelivery ? (
          <DeliveryTicketNumber
            delivery={delivery.relatedDelivery}
            toggles={toggles}
            customerId={customerId}
            analytics={analytics}
          />
        ) : (
          '-'
        )
    },
    description: {
      field: 'description',
      headerName: t('order.expand.serviceDescription'),
      noColumnSelection: true,
      renderCell: (delivery: Delivery) => (
        <PrimaryCell>{delivery.materialDescription || '-'}</PrimaryCell>
      )
    }
  }
  const materialColumns = [
    rowsPool.ticketNumber,
    !toggles.isRMC ? rowsPool.leftPlantAt : rowsPool.arrival,
    rowsPool.quantity,
    rowsPool.plantName,
    rowsPool.vehicle,
    rowsPool.status
  ]

  const serviceColumns = [
    rowsPool.relatedDelivery,
    rowsPool.description,
    rowsPool.quantity,
    rowsPool.plantName,
    rowsPool.status
  ]

  if (!isFetching && !deliveries?.length) return null

  const areServicesSelected = selectedSwitch === TableButtonSwitch.services
  const relevantDeliveries = (areServicesSelected ? serviceDeliveries : materialDeliveries) || []
  return (
    <div data-test-id="deliveries-table">
      <DeliveriesServicesSwitch
        t={t}
        disableServicesSwitch={serviceDeliveries.length === 0}
        disableDeliveriesSwitch={materialDeliveries.length === 0}
        setSelectedSwitch={setSelectedSwitch}
      />
      {smallScreen ? (
        <DeliveryCardList
          customerId={customerId}
          deliveries={relevantDeliveries}
          order={order}
          toggles={toggles}
          viewAll
          analytics={analytics}
          loading={isFetching}
          onCardClick={(delivery) => !delivery.isService && setSelectedDelivery(delivery)}
        />
      ) : (
        <ResponsiveTable
          columns={areServicesSelected ? serviceColumns : materialColumns}
          rows={relevantDeliveries}
          onRowClick={(row) => !row.isService && setSelectedDelivery(row)}
          onSort={() => {}}
          loading={isFetching}
          selectionEnabled={false}
          isRowSelectable={() => false}
          keyField="deliveryId"
          rowDetailsAvailable
        />
      )}
    </div>
  )
}
